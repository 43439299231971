import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';
import { templateViews } from './widgets.js';

let widgetProps = {};
for (var section of templateViews) {
    const { sections } = section;
    widgetProps[section.handle] = { sections };
}

export default {
    handle: 'groupInfo',
    label: 'Group Information',
    builderInitialValues,
    builder: Builder,
    player: Player,
    widgetProps,
};
