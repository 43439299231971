import React from 'react';
import { Field, CccisdInput, CccisdToggle, CccisdFieldWrapper } from 'cccisd-formik';
import Select from 'react-select';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';
import filterFields from '../filterFields.js';

export const initialValues = {
    title: 'Youth Information',
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const groups = [
    { value: 'site', label: 'Site' },
    { value: 'agency', label: 'Agency' },
    { value: 'organization', label: 'Organization' },
    { value: 'collaborator', label: 'Collaborator' },
];

const Component = props => {
    return (
        <>
            <BuilderBlock title="Header">
                <Field name="groupLevel">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form} label="Report Group Level">
                            <Select
                                defaultValue={groups.find(g => g.value === field.value)}
                                options={groups}
                                onChange={s => {
                                    form.setFieldValue('groupLevel', s.value);
                                }}
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
                <Field name="title" component={CccisdInput} label="Title" />
                <Field name="subtitle" component={CccisdInput} label="Subitle" />
            </BuilderBlock>
            <BuilderBlock title="Views Rendered">
                <Field name="demographics" component={CccisdToggle} label="Demographics" />
                <Field name="enrollment" component={CccisdToggle} label="Enrollment" />
                <Field name="outcomes" component={CccisdToggle} label="Outcomes" />
                <Field name="discharge" component={CccisdToggle} label="Discharge" />
            </BuilderBlock>
        </>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({ filterFields, widgets })(Component);
