import React from 'react';
import PropTypes from 'prop-types';
import HistoricalChart from '../HistoricalChart';
import query from './query.graphql';

const EnrollmentOverTime = props => {
    return (
        <HistoricalChart
            filters={props.filters}
            chart={props.chart}
            settings={props.settings}
            query={query}
        />
    );
};

EnrollmentOverTime.propTypes = {
    filters: PropTypes.object,
    chart: PropTypes.object,
    settings: PropTypes.object,
};

export default EnrollmentOverTime;
