import React from 'react';
import PropTypes from 'prop-types';
import AlternateHistoricalTable from '../AlternateHistoricalTable';
import query from './query.graphql';

const EnrollmentOverTime = props => {
    return (
        <AlternateHistoricalTable
            query={query}
            filters={props.filters}
            table={props.table}
            settings={props.settings}
            totalColumn="Youth Enrolled"
            hideTotalCol
            totalTooltip="Cumulative total of youth enrolled within the specified date range."
        />
    );
};

EnrollmentOverTime.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default EnrollmentOverTime;
