import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { mean, round } from 'lodash';

function SubscaleItemTable(props) {
    const { data, subscale, groupLevel, groupLevelMap } = props;

    const getData = () => {
        const rows = [];
        for (const group of Object.keys(groupLevelMap)) {
            const row = { groupLevel: group };
            for (const item of subscale.items) {
                const groupSiteItemAverages = [];
                let groupItemN = 0;
                if (data.flows[item]) {
                    for (const site of data.flows[item].frequency) {
                        if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                            if (site.count > 0) {
                                groupSiteItemAverages.push(site.avg);
                                groupItemN += site.count;
                            }
                        }
                    }
                }
                row[item] = { avg: mean(groupSiteItemAverages), count: groupItemN };
            }
            rows.push(row);
        }
        return rows;
    };

    const getTotal = td => {
        const totalRow = { groupLevel: 'Total:' };
        for (const row of td) {
            for (const key of Object.keys(row)) {
                if (key !== 'groupLevel' && row[key].avg >= 0) {
                    if (totalRow[key]) {
                        totalRow[key].push(row[key].avg);
                    } else {
                        totalRow[key] = [row[key].avg];
                    }
                }
            }
        }
        for (const k of Object.keys(totalRow)) {
            if (k !== 'groupLevel') {
                totalRow[k] = totalRow[k].length > 0 ? `${round((mean(totalRow[k]) / 2) * 100, 2)}%` : '';
            }
        }
        return totalRow;
    };

    const cols = [
        {
            name: 'groupLevel',
            sort: true,
            filter: true,
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        },
        ...subscale.items.map(item => ({
            name: item,
            label: item,
            tooltip: window.cccisd.appDefs.dartQuestions[item]?.question,
            render: value => (value.count > 0 ? `${round(value.avg, 2)} N=${value.count}` : 'N/A*'),
        })),
    ];

    const tableData = getData();
    const totalRow = getTotal(tableData);
    const showNotice = tableData.some(row => cols.some(col => !row[col.name].avg));

    return (
        <>
            <Table
                data={tableData}
                rowKey="groupLevel"
                orderBy="groupLevel"
                columns={cols}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[50, 250, 1000, 500]}
                isCsvDownload
                csvFilename={subscale.label.replaceAll(' ', '-').toLowerCase()}
            />
            {showNotice && (
                <p>
                    * All DARTs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
}

SubscaleItemTable.propTypes = {
    data: PropTypes.object,
    subscale: PropTypes.object,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default SubscaleItemTable;
