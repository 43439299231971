import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OtherOptOutReasonTable = props => {
    const getData = () => {
        let rows = [];
        const { deploymentList } = props.data.flows;
        // categorize by site - row per site
        if (deploymentList) {
            for (var cycle of deploymentList) {
                if (cycle.otherReasons.length > 0) {
                    const siteId = parseInt(cycle.siteId, 10);
                    const siteName = props.groups.find(g => g.group.groupId === siteId).group.label;
                    if (cycle.otherReasons) {
                        for (const res of cycle.otherReasons) {
                            if (res.devTagsLists.OptOutOther) {
                                for (const msg of res.devTagsLists.OptOutOther) {
                                    rows.push({
                                        siteRes: siteName + '<CONTROL>' + msg,
                                        response: msg,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'siteRes',
            label: 'Site',
            render: value => value.split('<CONTROL>')[0],
            sort: true,
            filter: true,
        },
        { name: 'response', label: 'Response' },
    ];

    const tableData = getData();

    return (
        <Table
            data={tableData}
            rowKey="siteRes"
            columns={cols}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="other-opt-out-reasons"
        />
    );
};

OtherOptOutReasonTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default OtherOptOutReasonTable;
