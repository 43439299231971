import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

export const templateViews = [
    {
        handle: 'activityLog',
        label: 'Activity Log',
        sections: [
            {
                name: 'activityLog',
                label: 'Activity Log',
                tables: [
                    {
                        name: 'activityLog',
                        label: 'Activity Log',
                        tableFilter: (filters, groupLevel) => {
                            const { siteSelectorField } = filters;
                            return {
                                AND: [
                                    {
                                        in: {
                                            field: 'group.groupId',
                                            int: siteSelectorField ? siteSelectorField : [],
                                        },
                                    },
                                ],
                            };
                        },
                    },
                ],
            },
        ],
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
