import React from 'react';
import PropTypes from 'prop-types';
import AlternateHistoricalTable from '../AlternateHistoricalTable';
import query from './query.graphql';

const DischargeOverTime = props => {
    return (
        <AlternateHistoricalTable
            query={query}
            filters={props.filters}
            table={props.table}
            settings={props.settings}
            totalColumn="Youth Discharged"
            totalTooltip="Cumulative total of youth discharged within the specified date range."
        />
    );
};

DischargeOverTime.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default DischargeOverTime;
