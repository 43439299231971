import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

import GroupInfoSection from './custom/sections/GroupInfoSection';

const groupInfoSection = [
    {
        name: 'groupInfo',
        label: 'Group Information',
        component: GroupInfoSection,
    },
];

export const templateViews = [
    {
        handle: 'groupInfo',
        label: 'Group Informaion',
        sections: groupInfoSection,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
