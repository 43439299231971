import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';
import { ccoTableFilter } from './helpers.js';

export const templateViews = [
    {
        handle: 'careCoordInfo',
        label: 'Care Coordinator Information',
        sections: [
            {
                name: 'youthAssigned',
                label: 'Youth Assigned',
                tables: [
                    {
                        name: 'youthAssigned',
                        label: 'Youth Assigned Table',
                        tableFilter: ccoTableFilter,
                    },
                ],
            },
            {
                name: 'ccoRetention',
                label: 'Care Coordinator Retention',
                tables: [
                    {
                        name: 'ccoRetention',
                        label: 'Care Coordinator Retention Table',
                        tableFilter: ccoTableFilter,
                    },
                ],
            },
            {
                name: 'ccoExitReason',
                label: 'Care Coordinator Exit Reason',
                tables: [
                    {
                        name: 'ccoExitReason',
                        label: 'Care Coordinator Exit Reason Table',
                        tableFilter: ccoTableFilter,
                    },
                ],
            },
        ],
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
