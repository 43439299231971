import React from 'react';
import PropTypes from 'prop-types';
import { mean, round } from 'lodash';
import { ResponsiveBar } from 'cccisd-nivo/bar';

function SubscalesOverallChart(props) {
    const { data, subscales, groupLevelMap } = props;

    const getData = () => {
        const rows = [];
        const bars = [];
        for (const group of Object.keys(groupLevelMap)) {
            const row = { groupLevel: group };
            for (const subscale of subscales) {
                if (data.flows[subscale.key]) {
                    for (const site of data.flows[subscale.key].frequency) {
                        if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                            if (site.count > 0) {
                                if (row[subscale.name]) {
                                    row[subscale.name].avg.push(site.avg);
                                    row[subscale.name].counts.push(site.count);
                                } else {
                                    row[subscale.name] = {
                                        avg: [site.avg],
                                        counts: [site.count],
                                    };
                                }
                            }
                        }
                    }
                }
            }
            rows.push(row);
        }
        const totalRow = { groupLevel: 'Total:' };
        for (const row of rows) {
            for (const key of Object.keys(row)) {
                if (key !== 'groupLevel' && row[key].avg) {
                    if (totalRow[key]) {
                        totalRow[key].push(mean(row[key].avg));
                    } else {
                        totalRow[key] = [mean(row[key].avg)];
                    }
                }
            }
        }
        for (const k of Object.keys(totalRow)) {
            if (k !== 'groupLevel') {
                totalRow[k] = totalRow[k].length > 0 ? round(mean(totalRow[k]), 2) : '';
            }
        }
        for (const subscale of subscales) {
            if (subscale.keyElement) {
                bars.push({
                    label: subscale.label,
                    'key element': totalRow[subscale.name] || 0,
                });
            } else {
                bars.push({
                    label: subscale.label,
                    overall: totalRow[subscale.name] || 0,
                });
            }
        }
        return bars;
    };

    const chartData = getData(data);

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsiveBar
                indexBy="label"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['overall', 'key element']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 200, left: 50 }}
            />
        </div>
    );
}

SubscalesOverallChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevelMap: PropTypes.object,
};

export default SubscalesOverallChart;
