import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { client } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const OtherResponseTable = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups) {
            setLoading(true);
            getGroups();
            getData();
        }
    }, [props.filters.siteSelectorField, props.groups]);

    const groupLevel = props.groupLevel || 'group';

    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    const getGroups = () => {
        function recurrsiveAncestoryLookup(node) {
            // base case
            if (!node.group.parentId) {
                return { [node.group.groupType]: node.group.label };
            }
            const parent = props.groups.find(group => group.group.groupId === node.group.parentId);
            return {
                [node.group.groupType]: node.group.label,
                ...(node.group.groupType !== groupLevel && recurrsiveAncestoryLookup(parent)),
            };
        }

        const siteTable = props.groups
            .filter(group => group.group.groupType === 'site')
            .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
        let levelMap = {};
        for (var site of siteTable) {
            if (site[groupLevel]) {
                if (site[groupLevel] in levelMap) {
                    levelMap[site[groupLevel]].push(site.id);
                } else {
                    levelMap[site[groupLevel]] = [site.id];
                }
            }
        }
        return levelMap;
    };

    const groups = getGroups();

    const flatten = obj => {
        return Object.assign(
            {},
            ...(function _flatten(o) {
                return [].concat(
                    ...Object.keys(o).map(k =>
                        typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] }
                    )
                );
            })(obj)
        );
    };

    const getData = async () => {
        const response = await client.query({
            query: props.query,
            variables: {
                sites: props.filters.siteSelectorField || [],
                fromDate: props.filters.monthSelectorFrom || null,
                toDate: props.filters.monthSelectorTo || null,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        const { youthList } = response.data.roles;
        let tableData = [];
        let responseMap = {};
        for (var group of Object.keys(groups)) {
            const youthInGroup = youthList.filter(y => groups[group].includes(y.pawn.groupId));
            for (var youth of youthInGroup) {
                let r = youth.fields[props.field];
                if (r !== null && r !== '') {
                    r = props.responsePrepend
                        ? flatten(youth)[props.responsePrepend] + ': ' + r.toLowerCase()
                        : r.toLowerCase();
                    if (responseMap[group]) {
                        if (responseMap[group][r]) {
                            responseMap[group][r] += 1;
                        } else {
                            responseMap[group][r] = 1;
                        }
                    } else {
                        responseMap[group] = { [r]: 1 };
                    }
                }
            }
        }
        for (var g of Object.keys(responseMap)) {
            for (var res of Object.keys(responseMap[g])) {
                const count = responseMap[g][res];
                tableData.push({ count, label: g, response: res });
            }
        }
        setData(tableData);
        setLoading(false);
    };

    const getColumns = () => {
        return [
            {
                name: 'label',
                label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
                sort: true,
                filter: true,
            },
            { name: 'response', label: 'Response', sort: true, filter: true },
            { name: 'count', label: 'Count', sort: true, filter: true },
        ];
    };

    if (data.length === 0 && !loading) {
        return <h4>There were no other responses for youth counted in this report</h4>;
    }

    if (loading) {
        return <Skeleton count={15} />;
    }

    return (
        <Table
            isCsvDownload
            columns={getColumns()}
            data={data}
            rowKey="label"
            orderBy="label"
            showPerPageOptions={false}
        />
    );
};

OtherResponseTable.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    field: PropTypes.string,
    table: PropTypes.object,
    query: PropTypes.object,
    responsePrepend: PropTypes.string,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, { loadGroups })(OtherResponseTable);
