import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';
import { templateViews } from './widgets.js';

let views = {};
for (var i = 0; i < templateViews.length; i++) {
    const view = templateViews[i];
    views[view.handle] = { sections: view.sections };
}
export default {
    handle: 'activityLog',
    label: 'Activity Log',
    builderInitialValues,
    builder: Builder,
    player: Player,
    widgetProps: {
        activityLog: {
            sections: views.activityLog.sections,
        },
    },
};
