import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';
import { templateViews } from './widgets.js';

let views = {};
for (var i = 0; i < templateViews.length; i++) {
    const view = templateViews[i];
    views[view.handle] = { sections: view.sections };
}

export default {
    handle: 'youthInformation',
    label: 'Youth Information',
    builderInitialValues,
    builder: Builder,
    player: Player,
    widgetProps: {
        demographics: {
            sections: views.demographics.sections,
        },
        discharge: {
            sections: views.discharge.sections,
        },
        enrollment: {
            sections: views.enrollment.sections,
        },
        outcomes: {
            sections: views.outcomes.sections,
        },
    },
};
