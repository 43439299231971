import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

import OutcomesSection from 'js/vendor/reports/components/sections/SystemInvolvementSection';

import { siteTableFilterSummary } from './queryFilters.js';

const respondentSummarySections = [
    {
        name: 'respondentSummary',
        label: 'Respondent Summary',
        tables: [
            {
                name: 'respondentSummary',
                label: 'Respondent Summary',
                tableFilter: siteTableFilterSummary,
            },
        ],
        charts: [],
    },
];

const outcomesSections = [
    {
        name: 'systemInvolvement',
        label: 'System Involvement',
        component: OutcomesSection,
    },
];

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: respondentSummarySections,
    },
    {
        handle: 'outcomes',
        label: 'Outcomes',
        sections: outcomesSections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
