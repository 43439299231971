import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const getData = () => {
        let rows = [];
        const { deploymentList } = props.data.flows;
        const siteMap = {};
        // categorize by site - row per site
        if (deploymentList) {
            for (var cycle of deploymentList) {
                const siteId = parseInt(cycle.siteId, 10);
                const siteName = props.groups.find(g => g.group.groupId === siteId).group.label;
                if (siteMap[siteName]) {
                    siteMap[siteName].time += cycle.timeConstraint;
                    siteMap[siteName].docs += cycle.doc;
                    siteMap[siteName].other += cycle.other;
                } else {
                    siteMap[siteName] = {
                        time: cycle.timeConstraint,
                        docs: cycle.doc,
                        other: cycle.other,
                    };
                }
            }

            for (const s of Object.keys(siteMap)) {
                rows.push({
                    site: s,
                    ...siteMap[s],
                });
            }
        }
        return rows;
    };

    const getTotal = td => {
        const total = td.reduce(
            (acc, curr) => {
                acc.time += curr.time;
                acc.docs += curr.docs;
                acc.other += curr.other;
                return acc;
            },
            {
                time: 0,
                docs: 0,
                other: 0,
            }
        );
        total.site = 'Total:';
        return total;
    };

    const cols = [
        { name: 'site', label: 'Site', sort: true, filter: true },
        { name: 'time', label: 'Unable to complete DART due to time constraint' },
        { name: 'docs', label: 'Unable to obtain documentation' },
        { name: 'other', label: 'Other' },
    ];

    const tableData = getData();

    return (
        <Table
            data={tableData}
            footer={getTotal(tableData)}
            rowKey="site"
            columns={cols}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="opt-out-reasons"
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default OptOutReasonTable;
