import React from 'react';
import PropTypes from 'prop-types';
import widgets, { templateViews } from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import TemplateWrapper from 'js/vendor/reports/components/TemplateWrapper';

import moment from 'moment';

const groupChildren = {
    site: [{ name: 'site', label: 'sites' }],
    agency: [
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
    organization: [
        { name: 'organization', label: 'organizations' },
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
    collaborator: [
        { name: 'collaborator', label: 'collaborators' },
        { name: 'organization', label: 'organizations' },
        { name: 'agency', label: 'agencies' },
        { name: 'site', label: 'sites' },
    ],
};

const Component = props => {
    const { siteSelectorField, monthSelectorFrom, monthSelectorTo } = props.filters;

    const getGroupCount = (level, sites) => {
        if (level === 'site') {
            return sites.length;
        }
        function recurrsiveAncestoryLookup(node) {
            // base case
            if (!node.group.parentId) {
                return { [node.group.groupType]: node.group.label };
            }
            const parent = props.groups.find(group => group.group.groupId === node.group.parentId);
            return {
                [node.group.groupType]: node.group.label,
                ...(node.group.groupType !== props.settings.groupLevel &&
                    recurrsiveAncestoryLookup(parent)),
            };
        }

        const siteTable = props.groups
            .filter(group => siteSelectorField.includes(group.group.groupId))
            .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
        return new Set(siteTable.map(s => s[level] && s[level]).filter(x => x !== undefined)).size;
    };

    const headerGroups = props.settings.groupLevel
        ? groupChildren[props.settings.groupLevel].map(g => ({
              title: `Number of ${g.label}`,
              value: getGroupCount(g.name, siteSelectorField),
              showIf: true,
          }))
        : [{ title: 'Groups', value: 'Select Group Level from Report Settings', showIf: true }];

    const summary = [
        ...headerGroups,
        {
            title: 'Date Range',
            value:
                monthSelectorFrom &&
                monthSelectorTo &&
                moment(monthSelectorFrom).format('MM/DD/YYYY') +
                    ' - ' +
                    moment(monthSelectorTo).format('MM/DD/YYYY'),
        },
    ];

    return (
        <TemplateWrapper
            settings={props.settings}
            summary={summary}
            initialFilterValues={props.initialFilterValues}
            filters={props.filters}
            widgetsConfig={widgets}
            widgetComponents={props.widgets}
            templateViews={templateViews}
            alwaysShownWidgets={['summary']}
            shouldShowWidget={w => true}
            showWhen
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    groups: PropTypes.array,
    defaultSites: PropTypes.array,
    initialFilterValues: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        const defaults = filterFields.map(f => ({
            ...f,
            initialValue: props.initialFilterValues[f.name],
        }));
        return defaults;
    },
})(Component);
