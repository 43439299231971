import React from 'react';
import SiteSelector from 'js/vendor/reports/filters/SiteSelector';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';
import MonthSelector from 'js/vendor/reports/filters/MonthSelector15th';
import FilterHack from 'js/vendor/reports/filters/FilterHack';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import style from './style.css';
// Define filters you want to use

export default [
    {
        name: 'siteSelectorField',
        label: 'Site Selector',
        component: props => {
            return (
                <Field name="siteSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <SiteSelector field={field} form={form} {...props} />
                                <InfoTooltip text="Select sites for inclusion in this report" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'monthSelectorFrom',
        label: 'Month Selector (From)',
        component: props => {
            return (
                <div className={style.flex} style={{ marginRight: '0px' }}>
                    <Field name="monthSelectorFrom">
                        {({ field, form }) => (
                            <CccisdFieldWrapper field={field} form={form}>
                                <MonthSelector startDate field={field} form={form} {...props} />
                            </CccisdFieldWrapper>
                        )}
                    </Field>
                    <div style={{ margin: '.25em .25em 0em .25em' }}>to</div>
                </div>
            );
        },
        isRequired: false,
    },
    {
        name: 'monthSelectorTo',
        label: 'Month Selector (To)',
        component: props => {
            return (
                <div className={style.flex}>
                    <Field name="monthSelectorTo">
                        {({ field, form }) => (
                            <CccisdFieldWrapper field={field} form={form}>
                                <MonthSelector endDate field={field} form={form} {...props} />
                            </CccisdFieldWrapper>
                        )}
                    </Field>
                    <InfoTooltip text="Youth are included in the report if they were active at all within the specified date range, even if they have been discharged." />
                </div>
            );
        },
        isRequired: false,
    },
    {
        name: 'filterHack',
        label: 'Enable Apply Filters',
        component: props => {
            return (
                <Field name="filterHack">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <FilterHack form={form} field={field} />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
];
