import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import groupInfoQuery from './query.graphql';

import GroupInfoTable from './tables/GroupInfoTable';

import { showTable, findAncestoryGroups } from 'js/vendor/reports/helpers.js';

function GroupInfoSection(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getData();
        }
    }, [props.filters.siteSelectorField]);

    const doQuery = () => {
        return taskMasterClient.query({
            query: groupInfoQuery,
            variables: {
                groupIds:
                    findAncestoryGroups(
                        props.filters.siteSelectorField,
                        props.groupLevel,
                        props.groups
                    ) || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    const CACHE_DATA = false;
    // localStorage.setItem('groupInfoData', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('groupInfoData'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('groupInfoData', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }

        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return <>{showTable(<GroupInfoTable data={data} groupLevel={props.groupLevel} />, loading)}</>;
}

GroupInfoSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(GroupInfoSection);
