import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';

const ExclusionInfoTable = props => {
    const exclusions = {
        invalidTE: {
            label: 'Invalid Timely Engagement',
            tooltip: 'Invalid if all D1-D7 are N/A and/or Miss',
        },
        invalidMA: {
            label: 'Invalid Meeting Attendance Subscale',
            tooltip: 'Invalid if all E1-E6 are N/A and/or Miss',
        },
        invalidCR: {
            label: 'Invalid Crisis Response Subscale',
            tooltip: '“Invalid if G1=0 or Miss',
        },
        invalidTP: {
            label: 'Invalid Transition Planning Subscale',
            tooltip: 'Invalid if H1 = No',
        },
        invalidTR: {
            label: 'Invalid Transition Reason',
            tooltip: 'Invalid if H1=No',
        },
        invalidOC: {
            label: 'Invalid Outcomes Subscale',
            tooltip: 'Invalid if all I1-I7 are N/A and/or Miss',
        },
        invalidEx: {
            label: 'Invalid DART Form',
            tooltip: '“Entire record invalid if C7, C8, or C9 = No',
        },
    };

    const getData = () => {
        let rows = [];
        const { deploymentList } = props.data.flows;
        let totalExclusions = {};
        for (var cycle of deploymentList) {
            for (var exc of Object.keys(exclusions)) {
                if (totalExclusions[exc]) {
                    totalExclusions[exc] += cycle[exc];
                } else {
                    totalExclusions[exc] = cycle[exc];
                }
            }
        }
        for (const s of Object.keys(totalExclusions)) {
            rows.push({
                filter: s,
                count: totalExclusions[s],
            });
        }
        return rows;
    };

    const cols = [
        {
            name: 'filter',
            label: 'Filter',
            render(value) {
                return (
                    <div style={{ display: 'flex' }}>
                        {exclusions[value].label}
                        <InfoTooltip text={exclusions[value].tooltip} />
                    </div>
                );
            },
        },
        { name: 'count', label: 'Count', sort: true },
    ];

    const tableData = getData();

    return <Table data={tableData} rowKey="filter" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

ExclusionInfoTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default ExclusionInfoTable;
