import React from 'react';
import PropTypes from 'prop-types';
import { mean, round } from 'lodash';
import { ResponsiveBar } from 'cccisd-nivo/bar';

function KeyElementsOverallChart(props) {
    const { data, groupLevelMap } = props;

    const getData = () => {
        const rows = [];
        for (const group of Object.keys(groupLevelMap)) {
            const groupSiteItemScores = [];
            for (const site of data.flows.KE.frequency) {
                if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                    if (site.count > 0) {
                        groupSiteItemScores.push(site.avg);
                    }
                }
            }

            if (groupSiteItemScores.length > 0) {
                rows.push({
                    groupLevel: group,
                    score: mean(groupSiteItemScores) || null,
                });
            }
        }
        return [
            {
                label: 'Key Element Overall Score',
                average: round(mean(rows.map(row => row.score && row.score).filter(s => s !== null)), 2),
            },
        ];
    };

    return (
        <div style={{ width: '770px', height: '350px' }}>
            <ResponsiveBar
                indexBy="label"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['average']}
                theme={{ fontSize: 16 }}
                data={getData()}
                margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
            />
        </div>
    );
}

KeyElementsOverallChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevelMap: PropTypes.object,
};

export default KeyElementsOverallChart;
