import React from 'react';
import PropTypes from 'prop-types';
import AlternateHistoricalTable from '../AlternateHistoricalTable';
import query from './query.graphql';

const YouthAddedOverTime = props => {
    return (
        <AlternateHistoricalTable
            query={query}
            filters={props.filters}
            table={props.table}
            settings={props.settings}
            totalColumn="Youth Added"
            totalTooltip="Cumulative total of new youth added within the specified date range."
        />
    );
};

YouthAddedOverTime.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default YouthAddedOverTime;
