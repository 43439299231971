import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const GroupInfoTable = props => {
    const { groupLevel } = props;

    const data = props.data.groups[groupLevel + 'List'];

    const getData = () => {
        // filter to get rows with data only
        const filteredData = data.filter(
            row => row.descendantRoles.metricspawn.assignmentProgressList.length > 0
        );
        let rows = [];
        for (var g of filteredData) {
            for (var response of g.descendantRoles.metricspawn.assignmentProgressList) {
                rows.push({
                    label: g.group.label || 'N/A',
                    agency: (g.agency && g.agency.agency.group.label) || 'N/A',
                    org: (g.organization && g.organization.organization.group.label) || 'N/A',
                    collab: (g.collaborator && g.collaborator.collaborator.group.label) || 'N/A',
                    year: response.completedDate.split('-')[0] || 'N/A',
                    type:
                        response.variables.Organization === 'Other'
                            ? response.variables.Organization_Oth
                            : response.variables.Organization,
                    founding: response.variables.Year || 'N/A',
                    population: response.variables.Population || 'N/A',
                    sitecount: response.variables.Positions || 'N/A',
                    turnover: response.variables.Positions_Left || 'N/A',
                    caseload: response.variables.Caseload || 'N/A',
                    supervisee: response.variables.Supervisees || 'N/A',
                    parentpeer: response.variables.P_Support || 'N/A',
                    youthpeer: response.variables.Y_Support || 'N/A',
                    curriculum: response.variables.Curriculum || 'N/A',
                });
            }
        }
        return rows;
    };

    const excludedCols = {
        site: [],
        agency: ['agency'],
        organization: ['agency', 'org'],
        collaborator: ['agency', 'org', 'collab'],
    };

    const setStyle = (value, row) => {
        return { width: '15%' };
    };

    const t1cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
            setStyle,
        },
        {
            name: 'year',
            label: 'Year',
            sort: true,
            filter: true,
            tooltip: 'The year the survey was taken.',
            setStyle,
        },
        { name: 'agency', label: 'Agency', setStyle },
        { name: 'org', label: 'Organization', setStyle },
        { name: 'collab', label: 'Collaborator', setStyle },
        { name: 'type', label: 'Organization Type', setStyle },
    ].filter(col => !excludedCols[groupLevel].includes(col.name));

    const t2cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
            setStyle,
        },
        {
            name: 'year',
            label: 'Year',
            sort: true,
            filter: true,
            tooltip: 'The year the survey was taken.',
            setStyle,
        },
        { name: 'founding', label: 'Founding Year' },
        { name: 'population', label: 'Population of Focus', setStyle },
        { name: 'sitecount', label: 'Site Count' },
        { name: 'turnover', label: 'Site Turnover' },
        { name: 'caseload', label: 'Site Caseload' },
    ];

    const t3cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
            setStyle,
        },
        {
            name: 'year',
            label: 'Year',
            sort: true,
            filter: true,
            tooltip: 'The year the survey was taken.',
            setStyle,
        },
        { name: 'supervisee', label: 'Supervisee Count' },
        { name: 'parentpeer', label: 'Parent Peer Support' },
        { name: 'youthpeer', label: 'Youth Peer Support' },
        { name: 'curriculum', label: 'Curriculum', setStyle },
    ];

    const d = getData();

    return (
        <>
            <Table
                data={d}
                rowKey="label"
                columns={t1cols}
                showPerPageOptions={false}
                isCsvDownload
            />
            <Table
                data={d}
                rowKey="label"
                columns={t2cols}
                showPerPageOptions={false}
                isCsvDownload
            />
            <Table
                data={d}
                rowKey="label"
                columns={t3cols}
                showPerPageOptions={false}
                isCsvDownload
            />
        </>
    );
};

GroupInfoTable.propTypes = {
    data: PropTypes.array,
    groupLevel: PropTypes.string,
};

export default GroupInfoTable;
