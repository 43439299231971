import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';
import { client } from 'cccisd-apollo';
import moment from 'moment';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { getGroupLevelMap } from 'js/vendor/reports/helpers.js';

const HistoricalChart = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const groupLevel = props.settings.groupLevel || 'group';

    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getData();
        }
    }, [
        props.filters.siteSelectorField,
        props.filters.historicalFilter,
        props.groups.length,
        props.filters.monthSelectorTo,
    ]);

    const getMonthCols = () => {
        const monthsAgo = props.filters.historicalFilter;
        const now = moment(props.filters.monthSelectorTo);
        let cols = [];
        for (var i = 0; i < monthsAgo; i++) {
            let d;
            // if past the 15th and first iteration
            if (now.day() > 15 && i === 0) {
                d = now.format('YYYY-MM');
            }
            d = now.subtract(1, 'months');
            cols.push({
                name: d.format('YYYY-MM'),
                label: d.format('MMM-YY'),
            });
        }
        return cols;
    };

    const monthCols = getMonthCols();

    const getData = async () => {
        var monthVars = {};
        const defaultMonths = 6;
        const cuttoffSuffix = '-15';
        var i = 1;
        for (var mo of monthCols.reverse()) {
            monthVars['month' + i] = mo.name + cuttoffSuffix;
            i++;
        }
        if (props.filters.historicalFilter === defaultMonths) {
            for (var v = defaultMonths + 1; v <= 13; v++) {
                monthVars['month' + v] = monthCols[0].name + cuttoffSuffix;
            }
        }
        monthVars['month' + (monthCols.length + 1)] = moment(monthVars['month' + monthCols.length])
            .add(1, 'M')
            .format('YYYY-MM-DD');
        const lines = [];
        const response = await client.query({
            query: props.query,
            variables: {
                sites: props.filters.siteSelectorField || [],
                fromDate: props.filters.monthSelectorFrom || null,
                toDate: props.filters.monthSelectorTo || null,
                ...monthVars,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        const { siteList } = response.data.groups;
        const groupLevelMap = getGroupLevelMap(
            props.groups,
            groupLevel,
            props.filters.siteSelectorField
        );
        for (var group of Object.keys(groupLevelMap)) {
            let groupData = { id: group, data: [] };
            var hasData = false;
            for (var m of Object.keys(monthVars).slice(0, props.filters.historicalFilter)) {
                let total = 0;
                for (var siteId of groupLevelMap[group]) {
                    const site = siteList.find(s => s.group.groupId === siteId).descendantRoles;
                    total += site[m];
                    if (site[m] > 0) {
                        hasData = true;
                    }
                }
                groupData.data.push({
                    x: moment(monthVars[m].substring(0, 7)).format('MMM-YY'),
                    y: total,
                });
            }
            if (hasData) {
                lines.push(groupData);
            }
        }
        setData(lines);
        setLoading(false);
        props.setWidgetLoaded(props.chart.name + 'Chart');
    };

    if (loading) {
        return <Skeleton height={300} />;
    }

    return (
        <div style={props.chart.size || { width: '770px', height: '500px' }}>
            <ResponsiveLine
                data={data}
                theme={{ fontSize: 16 }}
                legends={props.chart.legend}
                axisBottom={{
                    tickRotation: 45,
                }}
                margin={props.chart.margin || { top: 10, right: 150, bottom: 70, left: 50 }}
            />
        </div>
    );
};

HistoricalChart.propTypes = {
    filters: PropTypes.object,
    chart: PropTypes.object,
    query: PropTypes.object,
    settings: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(HistoricalChart);
