import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { mean, round, sum } from 'lodash';

function SubscaleOverallTables(props) {
    const { data, subscales, groupLevel, groupLevelMap } = props;

    const cols1 = [
        {
            name: 'groupLevel',
            filter: true,
            sort: true,
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        },
        ...subscales.slice(0, 5).map(subscale => ({
            name: subscale.name,
            label: subscale.label,
            render: value => (value ? `${round(value.avg, 2)}% N=${value.count}` : 'N/A*'),
        })),
    ];

    const cols2 = [
        {
            name: 'groupLevel',
            filter: true,
            sort: true,
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        },
        ...subscales.slice(5, subscales.length).map(subscale => ({
            name: subscale.name,
            label: subscale.label,
            render: value => (value ? `${round(value.avg, 2)}% N=${value.count}` : 'N/A*'),
        })),
    ];

    //   groupItemN += data.flows[subscale.key].frequency.find(s => s.value === site.value).count;

    const getData = () => {
        let rows = [];
        for (const group of Object.keys(groupLevelMap)) {
            const row = { groupLevel: group };

            for (const subscale of subscales) {
                if (data.flows[subscale.key]) {
                    for (const site of data.flows[subscale.key].frequency) {
                        if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                            if (site.count > 0) {
                                if (row[subscale.name]) {
                                    row[subscale.name].avg.push(site.avg);
                                    row[subscale.name].counts.push(site.count);
                                } else {
                                    row[subscale.name] = {
                                        avg: [site.avg],
                                        counts: [site.count],
                                    };
                                }
                            }
                        }
                    }
                }
            }
            rows.push(row);
        }
        const newRows = [];
        for (const row of rows) {
            const newRow = { groupLevel: row.groupLevel };
            for (const key of Object.keys(row)) {
                if (!['groupLevel'].includes(key)) {
                    newRow[key] = { avg: mean(row[key].avg), count: sum(row[key].counts) };
                }
            }
            if (Object.keys(newRow).length > 1) {
                newRows.push(newRow);
            }
        }
        return newRows;
    };

    const getTotal = td => {
        const totalRow = { groupLevel: 'Total:' };
        for (const row of td) {
            for (const key of Object.keys(row)) {
                if (key !== 'groupLevel') {
                    if (totalRow[key]) {
                        totalRow[key].push(row[key].avg);
                    } else {
                        totalRow[key] = [row[key].avg];
                    }
                }
            }
        }
        for (const k of Object.keys(totalRow)) {
            if (k !== 'groupLevel') {
                totalRow[k] = `${round(mean(totalRow[k]), 2)}%`;
            }
        }
        return totalRow;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);
    const showNotice = tableData.some(row => [...cols1, ...cols2].some(col => !row[col.name].avg));

    return (
        <>
            <div style={{ marginTop: '3em' }}>
                <Table
                    data={tableData}
                    rowKey="groupLevel"
                    orderBy="groupLevel"
                    columns={cols1}
                    footer={totalRow}
                    perPage={5000}
                    perPageOptions={[25, 50, 100, 500]}
                    isCsvDownload
                    csvFilename="subscales-table-1"
                />
                {showNotice && (
                    <p>
                        * All DARTs for this item were scored as &quot;N/A&quot;, thus a summary score can not be
                        calculated
                    </p>
                )}
            </div>

            <div style={{ marginTop: '3em' }}>
                <Table
                    data={tableData}
                    rowKey="groupLevel"
                    orderBy="groupLevel"
                    columns={cols2}
                    footer={totalRow}
                    perPage={5000}
                    perPageOptions={[25, 50, 100, 500]}
                    isCsvDownload
                    csvFilename="subscales-table-2"
                />
                {showNotice && (
                    <p>
                        * All DARTs for this item were scored as &quot;N/A&quot;, thus a summary score can not be
                        calculated
                    </p>
                )}
            </div>
        </>
    );
}

SubscaleOverallTables.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default SubscaleOverallTables;
