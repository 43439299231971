import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DuplicateInfoTable = props => {
    const getData = () => {
        let rows = [];
        let wrapIdCycleMap = {};
        const { deploymentList } = props.data.flows;
        for (var cycle of deploymentList) {
            for (var pawn of cycle.assignmentProgressList) {
                const { wrapId } = pawn.pawn.fields;
                if (wrapIdCycleMap[wrapId] && !wrapIdCycleMap[wrapId].includes(cycle.deploymentId)) {
                    wrapIdCycleMap[wrapId].push(cycle.deploymentId);
                } else {
                    wrapIdCycleMap[wrapId] = [cycle.deploymentId];
                }
            }
        }
        for (const w of Object.keys(wrapIdCycleMap)) {
            if (wrapIdCycleMap[w].length > 1) {
                const site = props.groups.find(
                    g =>
                        g.group.groupId ===
                        parseInt(deploymentList.find(d => d.deploymentId === wrapIdCycleMap[w][0]).siteId, 10)
                );
                rows.push({
                    wrapId: w,
                    site: site.group.label,
                    cycles: deploymentList.filter(c => wrapIdCycleMap[w].includes(c.deploymentId)).map(cy => cy.label),
                });
            }
        }
        return rows;
    };

    const cols = [
        { name: 'wrapId', label: 'Wrap ID' },
        { name: 'site', label: 'Site' },
        { name: 'cycles', label: 'Evaluation Cycles', render: value => value.join(', ') },
    ];

    const tableData = getData();

    return (
        <Table
            data={tableData}
            rowKey="wrapId"
            columns={cols}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="duplicate-information"
        />
    );
};

DuplicateInfoTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default DuplicateInfoTable;
