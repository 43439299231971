import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import widgets, { templateViews } from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import { client, taskMasterClient } from 'cccisd-apollo';
import youthCountQuery from '../graphql/youthPawnIds.graphql';
import moment from 'moment';
import TemplateWrapper from 'js/vendor/reports/components/TemplateWrapper';
import { groupChildren, getGroupCount } from 'js/vendor/reports/helpers.js';
import { resetWidgetsLoaded, setYouthPawnIds } from 'js/reducers/report.js';
import hash from 'object-hash';

const Component = props => {
    const {
        siteSelectorField,
        monthSelectorFrom,
        monthSelectorTo,
        showHideView,
        historicalFilter,
    } = props.filters;
    const { settings, groups } = props;

    const loading = 'Loading...';

    const [youthCount, setYouthCount] = useState(loading);

    const filtersChanged = hash(props.initialFilterValues) !== hash(props.filters);

    const getData = async () => {
        await getYouthCount();
    };

    useEffect(() => {
        client.cancelQueries('report');
        taskMasterClient.cancelQueries('report');
        props.resetWidgetsLoaded();
        if (siteSelectorField && filtersChanged) {
            getData();
        }
    }, [siteSelectorField, monthSelectorFrom, monthSelectorTo, filtersChanged]);

    const headerGroups = settings.groupLevel
        ? groupChildren[settings.groupLevel].map(g => ({
              title: `Number of ${g.label}`,
              value: getGroupCount(g.name, siteSelectorField, groups),
              showIf: true,
          }))
        : [{ title: 'Groups', value: 'Select Group Level from Report Settings', showIf: true }];

    const summary = [
        ...headerGroups,
        {
            title: 'Date Range',
            value:
                monthSelectorFrom &&
                monthSelectorTo &&
                moment(monthSelectorFrom).format('MM/DD/YYYY') +
                    ' - ' +
                    moment(monthSelectorTo).format('MM/DD/YYYY'),
            showIf:
                settings.filterFields.includes('monthSelectorFrom') &&
                settings.filterFields.includes('monthSelectorTo'),
        },
        {
            title: 'Historical Range',
            value: historicalFilter && `${historicalFilter} Months`,
            showIf:
                settings.filterFields.includes('historicalFilter') &&
                settings.filterFields.includes('monthSelectorTo'),
        },
    ];
    // add the non-filter related summary items
    summary.push({
        title: 'Number of Youth',
        value: youthCount,
        showIf: true,
    });

    const getYouthCount = async siteIds => {
        setYouthCount(loading);
        const filter = {
            AND: [{ in: { field: 'parentGroup.site.group.groupId', int: siteSelectorField } }],
        };
        const response = await client.query({
            query: youthCountQuery,
            variables: { filter, fromDate: monthSelectorFrom, toDate: monthSelectorTo, limit: -1 },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        props.setYouthPawnIds(response.data.roles.youthList.map(y => y.pawn.pawnId));
        setYouthCount(response.data.roles.totalCount);
    };

    return (
        <TemplateWrapper
            settings={props.settings}
            summary={summary}
            initialFilterValues={props.initialFilterValues}
            filters={props.filters}
            widgetsConfig={widgets}
            widgetComponents={props.widgets}
            templateViews={templateViews}
            alwaysShownWidgets={[]}
            shouldShowWidget={w =>
                settings[w.handle] && showHideView.includes(w.label) && youthCount !== loading
            }
            showWhen={youthCount !== loading}
            showError={youthCount === 0}
            errorText="There are zero youth for this filter selection. Try selecting different options."
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    initialFilterValues: PropTypes.object,
    widgets: PropTypes.object,
    groups: PropTypes.array,
    defaultSites: PropTypes.array,
    // redux
    resetWidgetsLoaded: PropTypes.func,
    setYouthPawnIds: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    youthPawnIds: state.app.report.youthPawnIds,
});
const Connected = connect(mapStateToProps, { resetWidgetsLoaded, setYouthPawnIds })(Component);

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        const defaults = filterFields.map(f => ({
            ...f,
            initialValue: props.initialFilterValues[f.name],
        }));
        return defaults;
    },
})(Connected);

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
