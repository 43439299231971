import { startCase, mean } from 'lodash';
const { keys } = Object;

export const std = arr => {
    return Math.sqrt(
        arr.reduce((acc, val) => acc.concat((val - mean(arr)) ** 2), []).reduce((acc, val) => acc + val, 0) / arr.length
    );
};

export const commonColumns = groupLevel => [
    {
        name: 'groupLevel',
        label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        filter: true,
        sort: true,
    },
    {
        name: 'numberOfYouth',
        label: 'Number of Youth',
        sort: true,
        render: value => value || 0,
    },
];

export const pieLegend = [
    {
        anchor: 'top-left',
        direction: 'column',
        justify: false,
        translateX: -200,
        translateY: 0,
        itemWidth: 100,
        itemHeight: 20,
        itemsSpacing: 0,
        symbolSize: 20,
        itemDirection: 'left-to-right',
    },
];

export const convertDemographicsPieChart = (data, section, _) => {
    let counts = {};
    let totalCount = 0;
    for (const field of keys(data.roles)) {
        if (field.startsWith(section.name) && !field.includes('otherResponses')) {
            const val = field.split('_')[1];
            if (data.roles[field].frequency) {
                for (const cc of data.roles[field].frequency) {
                    for (const count of cc.frequency) {
                        if (count.value || count.value === 0 || val === 'missing') {
                            totalCount += count.valueCount;
                            if (counts[val]) {
                                counts[val] += count.valueCount;
                            } else {
                                counts[val] = count.valueCount;
                            }
                        }
                    }
                }
            }
        }
    }
    const piedata = keys(counts).map(a => ({
        id: startCase(a),
        label: `${startCase(a)} (${counts[a] > 0 ? ((counts[a] / totalCount) * 100).toFixed(2) : 0}%)`,
        value: `${counts[a] || 0}`,
    }));
    for (const f of keys(data.roles)) {
        if (f.startsWith(section.name) && !f.includes('otherResponses')) {
            const v = f.split('_')[1];
            if (!keys(counts).includes(v)) {
                piedata.push({
                    id: startCase(v),
                    label: `${startCase(v)} (0.00%)`,
                    value: 0,
                });
            }
        }
    }
    return piedata;
};

export const convertDemographicsBarChart = (data, section, _) => {
    let counts = {};
    for (const field of keys(data.roles)) {
        if (field.startsWith(section.name) && !field.includes('otherResponses')) {
            const val = field.split('_')[1];
            if (data.roles[field].frequency) {
                for (const cc of data.roles[field].frequency) {
                    for (const count of cc.frequency) {
                        if (count.value || field.includes('missing')) {
                            if (counts[val]) {
                                counts[val] += count.valueCount;
                            } else {
                                counts[val] = count.valueCount;
                            }
                        }
                    }
                }
            }
        }
    }
    const bardata = keys(counts).map(a => ({
        id: startCase(a),
        count: counts[a] || 0,
    }));
    for (const f of keys(data.roles)) {
        if (f.startsWith(section.name) && !f.includes('otherResponses')) {
            const v = f.split('_')[1];
            if (!keys(counts).includes(v)) {
                bardata.push({
                    id: startCase(v),
                    count: 0,
                });
            }
        }
    }
    const order = [
        ...section.columns.map(c => startCase(c.name)),
        ...section.columnsCont.map(cc => startCase(cc.name)),
    ];
    bardata.sort((a, b) => {
        const aIndex = order.indexOf(a.id);
        const bIndex = order.indexOf(b.id);
        return aIndex - bIndex;
    });
    return bardata;
};

export const convertAgeAtEnrollment = (data, _, __) => {
    const underflow = '0+';
    const overflow = '19+';
    const sortedKeys = [
        '0+',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19+',
    ];
    const freq = {
        [underflow]: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        [overflow]: 0,
    };
    for (const siteFreq of data.roles.ageAtEnrollment.frequency) {
        for (const age of siteFreq.frequency) {
            const yearsOld = parseInt(age.value, 10);
            if (yearsOld > 18) {
                freq[overflow] += age.valueCount;
            }
            if (yearsOld < 1) {
                freq[underflow] += age.valueCount;
            } else if (keys(freq).includes(age.value)) {
                freq[age.value] += age.valueCount;
            }
        }
    }
    return sortedKeys.map(a => ({ id: a, count: freq[a] }));
};
