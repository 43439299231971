import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

// queries
import ageAtEnrollment from './graphql/ageAtEnrollment.graphql';
import genderIdentity from './graphql/genderIdentity.graphql';
import transgenderIdentity from './graphql/transgenderIdentity.graphql';
import sexualOrientation from './graphql/sexualOrientation.graphql';
import race from './graphql/race.graphql';
import ethnicity from './graphql/ethnicity.graphql';
import preferredLanguage from './graphql/preferredLanguage.graphql';
import legalCustodian from './graphql/legalCustodian.graphql';
import exitReason from './graphql/exitReason.graphql';
import referralSource from './graphql/referralSource.graphql';

// components
import DischargeOverTime from './custom/tables/DischargeOverTime';
import DischargeOverTimeChart from './custom/charts/DischargeOverTime';
import EnrollmentOverTime from './custom/tables/EnrollmentOverTime';
import EnrollmentOverTimeChart from './custom/charts/EnrollmentOverTime';
import YouthAddedOverTime from './custom/tables/YouthAddedOverTime';
import YouthAddedOverTimeChart from './custom/charts/YouthAddedOverTime';

import OtherGenderIdentity from './custom/tables/OtherGenderIdentity';
import OtherSexualOrientation from './custom/tables/OtherSexualOrientation';
import OtherRace from './custom/tables/OtherRace';
import OtherLegalCustodian from './custom/tables/OtherLegalCustodian';
import OtherPreferredLanguage from './custom/tables/OtherPreferredLanguage';
import OtherReferralSource from './custom/tables/OtherReferralSource';
import OtherExitReason from './custom/tables/OtherExitReason';

import OutcomesSection from 'js/vendor/reports/components/sections/SystemInvolvementSection';
// helpers
import { pieLegend, lineLegend } from 'js/vendor/reports/helpers.js';

import { chartFilter, siteTableFilter } from './queryFilters.js';
import {
    ageAtEnrollmentConvertData,
    convertDemographicsChart,
    convertDemographicsBarChart,
    convertExitReasonBarChart,
} from './convert.js';

const demographicsSections = [
    {
        name: 'ageAtEnrollment',
        label: 'Age at Enrollment',
        tables: [
            {
                name: 'ageAtEnrollment',
                label: 'Age at Enrollment',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ageAtEnrollment',
                position: 'top',
                type: 'bar',
                query: ageAtEnrollment,
                convertData: ageAtEnrollmentConvertData,
                chartFilter,
            },
        ],
    },
    {
        name: 'genderIdentity',
        label: 'Gender Identity',
        tables: [
            {
                name: 'genderIdentity',
                label: 'Gender Identity',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherGenderIdentity',
                label: 'Gender Identity "Other" Responses',
                component: OtherGenderIdentity,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'genderIdentity',
                position: 'top',
                type: 'pie',
                query: genderIdentity,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'transgenderIdentity',
        label: 'Transgender Identity',
        tables: [
            {
                name: 'transgenderIdentity',
                label: 'Transgender Identity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'transgenderIdentity',
                position: 'top',
                type: 'pie',
                query: transgenderIdentity,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'sexualOrientation',
        label: 'Sexual Orientation',
        tables: [
            {
                name: 'sexualOrientation',
                label: 'Sexual Orientation',
                tableFilter: siteTableFilter,
            },
            {
                name: 'sexualOrientation2',
                label: 'Sexual Orientation (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherSexualOrientation',
                label: 'Sexual Orientation "Other" Responses',
                component: OtherSexualOrientation,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'sexualOrientation',
                position: 'top',
                type: 'pie',
                query: sexualOrientation,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'race',
        label: 'Race',
        tables: [
            {
                name: 'race',
                label: 'Race',
                tableFilter: siteTableFilter,
            },
            {
                name: 'race2',
                label: 'Race (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherRace',
                label: 'Race "Other" Responses',
                component: OtherRace,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'race',
                position: 'top',
                type: 'pie',
                query: race,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'ethnicity',
        label: 'Ethnicity',
        tables: [
            {
                name: 'ethnicity',
                label: 'Ethnicity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ethnicity',
                position: 'top',
                type: 'pie',
                query: ethnicity,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'legalCustodian',
        label: 'Legal Custodian',
        tables: [
            {
                name: 'legalCustodian',
                label: 'Legal Custodian',
                tableFilter: siteTableFilter,
            },
            {
                name: 'legalCustodian2',
                label: 'Legal Custodian (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherLegalCustodian',
                label: 'Legal Custodian "Other" Responses',
                component: OtherLegalCustodian,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'legalCustodian',
                position: 'top',
                type: 'bar',
                query: legalCustodian,
                convertData: convertDemographicsBarChart,
                chartFilter,
                margin: { top: 50, right: 130, bottom: 100, left: 60 },
                size: { width: '770px', height: '450px' },
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'preferredLanguage',
        label: 'Preferred Language',
        tables: [
            {
                name: 'preferredLanguage',
                label: 'Preferred Language',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherPreferredLanguage',
                label: 'Preferred Language "Other" Responses',
                component: OtherPreferredLanguage,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'preferredLanguage',
                position: 'top',
                type: 'pie',
                query: preferredLanguage,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
    },
];

const dischargeSections = [
    {
        name: 'dischargeInformation',
        label: 'Discharge Information',
        tables: [
            {
                name: 'dischargeInformation',
                label: 'Discharge Information',
                tableFilter: siteTableFilter,
            },
        ],
    },
    {
        name: 'dischargeOverTime',
        label: 'Discharge Over Time',
        tables: [
            {
                name: 'dischargeOverTime',
                label: 'Discharge Over Time',
                component: DischargeOverTime,
            },
        ],
        charts: [
            {
                name: 'dischargeOverTime',
                position: 'top',
                type: 'line',
                component: DischargeOverTimeChart,
                legend: lineLegend,
            },
        ],
    },
    {
        name: 'exitReason',
        label: 'Exit Reason',
        tables: [
            { name: 'exitReason', label: 'Exit Reason', tableFilter: siteTableFilter },
            {
                name: 'exitReason2',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'exitReason3',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherExitReason',
                label: 'Exit Reason "Other" Responses',
                component: OtherExitReason,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'exitReason',
                position: 'top',
                type: 'bar',
                query: exitReason,
                convertData: convertExitReasonBarChart,
                chartFilter,
                legend: pieLegend,
                margin: { top: 50, right: 130, bottom: 150, left: 60 },
                size: { width: '770px', height: '450px' },
            },
        ],
    },
];
const enrollmentSections = [
    {
        name: 'activelyEnrolledYouth',
        label: 'Actively Enrolled Youth',
        tables: [
            {
                name: 'activelyEnrolledYouth',
                label: 'Actively Enrolled Youth',
                tableFilter: siteTableFilter,
            },
        ],
    },
    {
        name: 'enrollmentOverTime',
        label: 'Enrollment Over Time',
        tables: [
            {
                name: 'enrollmentOverTime',
                label: 'Enrollment Over Time',
                component: EnrollmentOverTime,
            },
        ],
        charts: [
            {
                name: 'enrollmentOverTime',
                position: 'top',
                type: 'line',
                component: EnrollmentOverTimeChart,
                legend: lineLegend,
            },
        ],
    },
    {
        name: 'youthAddedOverTime',
        label: 'Youth Added Over Time',
        tables: [
            {
                name: 'youthAddedOverTime',
                label: 'Youth Added Over Time',
                component: YouthAddedOverTime,
            },
        ],
        charts: [
            {
                name: 'youthAddedOverTime',
                position: 'top',
                type: 'line',
                component: YouthAddedOverTimeChart,
                legend: lineLegend,
            },
        ],
    },
    {
        name: 'referralSource',
        label: 'Referral Source',
        charts: [
            {
                name: 'referralSource',
                position: 'top',
                type: 'pie',
                query: referralSource,
                convertData: convertDemographicsChart,
                chartFilter,
                legend: pieLegend,
            },
        ],
        tables: [
            { name: 'referralSource', label: 'Referral Source', tableFilter: siteTableFilter },
            {
                name: 'referralSource2',
                label: 'Referral Source (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherReferralSource',
                label: 'Referral Source "Other" Responses',
                component: OtherReferralSource,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
    },
];
const outcomesSections = [
    {
        name: 'systemInvolvement',
        label: 'System Involvement',
        component: OutcomesSection,
    },
];

export const templateViews = [
    {
        handle: 'demographics',
        label: 'Demographics',
        sections: demographicsSections,
    },
    {
        handle: 'enrollment',
        label: 'Enrollment',
        sections: enrollmentSections,
    },
    {
        handle: 'outcomes',
        label: 'Outcomes',
        sections: outcomesSections,
    },
    {
        handle: 'discharge',
        label: 'Discharge',
        sections: dischargeSections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
