export const ccoTableFilter = (filters, groupLevel) => {
    const { siteSelectorField } = filters;
    if (groupLevel === 'site') {
        return {
            AND: [
                {
                    in: {
                        field: 'group.groupId',
                        int: siteSelectorField ? siteSelectorField : [],
                    },
                },
            ],
        };
    }
    return {
        AND: [
            {
                in: {
                    field: 'descendantGroups.site.group.groupId',
                    int: siteSelectorField ? siteSelectorField : [],
                },
            },
        ],
    };
};
