import React from 'react';
import SiteSelector from 'js/vendor/reports/filters/SiteSelector';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';
import FilterHack from 'js/vendor/reports/filters/FilterHack';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import style from 'js/vendor/reports/style.css';

export default [
    {
        name: 'siteSelectorField',
        label: 'Site Selector',
        component: props => {
            return (
                <Field name="siteSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <SiteSelector field={field} form={form} {...props} />
                                <InfoTooltip text="Select sites for inclusion in this report" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'filterHack',
        label: 'Enable Apply Filters',
        component: props => {
            return (
                <Field name="filterHack">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <FilterHack form={form} field={field} />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
];
