import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { client } from 'cccisd-apollo';
import moment from 'moment';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { setWidgetLoaded } from 'js/reducers/report.js';

const AlternateHistoricalTable = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const groupLevel = props.settings.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getData();
        }
    }, [
        props.filters.siteSelectorField,
        props.filters.historicalFilter,
        props.groups.length,
        props.filters.monthSelectorTo,
    ]);

    const getGroups = () => {
        function recurrsiveAncestoryLookup(node) {
            // base case
            if (!node.group.parentId) {
                return { [node.group.groupType]: node.group.label };
            }
            const parent = props.groups.find(group => group.group.groupId === node.group.parentId);
            return {
                [node.group.groupType]: node.group.label,
                ...(node.group.groupType !== groupLevel && recurrsiveAncestoryLookup(parent)),
            };
        }

        const siteTable = props.groups
            .filter(
                group =>
                    group.group.groupType === 'site' &&
                    props.filters.siteSelectorField.includes(group.group.groupId)
            )
            .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
        let levelMap = {};
        for (var site of siteTable) {
            if (site[groupLevel]) {
                if (site[groupLevel] in levelMap) {
                    levelMap[site[groupLevel]].push(site.id);
                } else {
                    levelMap[site[groupLevel]] = [site.id];
                }
            }
        }
        return levelMap;
    };

    const getMonthCols = () => {
        const monthsAgo = props.filters.historicalFilter;
        const now = moment(props.filters.monthSelectorTo);
        const chunkSize = 6;
        let cols = [];
        let tables = [];
        for (var i = 0; i < monthsAgo; i++) {
            let d;
            // if past the 15th and first iteration
            if (now.day() > 15 && i === 0) {
                d = now.format('YYYY-MM');
            }
            d = now.subtract(1, 'months');
            cols.push({
                name: d.format('YYYY-MM'),
                label: d.format('MMM-YY'),
                filter: true,
                sort: true,
            });
            if ((i + 1) % chunkSize === 0) {
                tables.push(cols.reverse());
                cols = [];
            }
        }
        return tables.reverse();
    };

    const monthCols = getMonthCols();

    const getData = async () => {
        const cuttoffSuffix = '-15';
        const allTablesData = [];
        // do a query for each table (1 if 6mo - 2 if 12mo)
        for (var months of monthCols) {
            const tableData = [];
            let totalRow = {
                label: 'Total:',
            };
            const monthVars = {
                month1: months[0].name + cuttoffSuffix,
                month2: months[1].name + cuttoffSuffix,
                month3: months[2].name + cuttoffSuffix,
                month4: months[3].name + cuttoffSuffix,
                month5: months[4].name + cuttoffSuffix,
                month6: months[5].name + cuttoffSuffix,
                month7: moment(months[5].name).add(1, 'M').format('YYYY-MM') + cuttoffSuffix,
            };
            const response = await client.query({
                query: props.query,
                variables: {
                    sites: props.filters.siteSelectorField || [],
                    fromDate: props.filters.monthSelectorFrom || null,
                    toDate: props.filters.monthSelectorTo || null,
                    ...monthVars,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                cancelTag: 'report',
            });
            const { siteList } = response.data.groups;
            const groups = getGroups();
            for (var group of Object.keys(groups)) {
                let dataItem = {
                    label: group,
                };
                let total = 0;
                for (var siteId of groups[group]) {
                    const site = siteList.find(s => s.group.groupId === siteId).descendantRoles;
                    for (var m of Object.keys(monthVars).slice(0, 6)) {
                        const colKey = monthVars[m].substring(0, 7);
                        if (dataItem[colKey]) {
                            dataItem[colKey] += site[m];
                        } else {
                            dataItem[colKey] = site[m];
                        }
                        total += site[m];
                        if (totalRow[colKey]) {
                            totalRow[colKey] += site[m];
                        } else {
                            totalRow[colKey] = site[m];
                        }
                    }
                }
                if (!props.hideTotalCol) {
                    dataItem.total = total;
                }
                tableData.push(dataItem);
            }
            tableData.push(totalRow);
            allTablesData.push(tableData);
        }
        setData(allTablesData);
        setLoading(false);
        props.setWidgetLoaded(props.table.name + 'Table');
    };

    const getColumns = () => {
        const cols = monthCols.map(m =>
            [
                {
                    name: 'label',
                    label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
                    sort: true,
                    filter: true,
                },
                ...m,
                {
                    name: 'total',
                    label: `Total ${props.totalColumn}`,
                    tooltip: props.totalTooltip,
                    sort: true,
                    filter: true,
                },
            ].filter(c => {
                if (props.hideTotalCol && c.name === 'total') {
                    return false;
                }
                return true;
            })
        );

        return cols;
    };

    if (loading) {
        return <Skeleton count={15} />;
    }

    return (
        <>
            {data.map((dataset, i) => {
                let foot = dataset[dataset.length - 1];
                return (
                    <div key={i}>
                        <Table
                            isCsvDownload
                            columns={getColumns()[i]}
                            data={dataset.slice(0, dataset.length - 1)}
                            rowKey="label"
                            orderBy="label"
                            showPerPageOptions={false}
                            footer={foot}
                        />
                        {i < data.length - 1 && <h4>Continued table...</h4>}
                    </div>
                );
            })}
        </>
    );
};

AlternateHistoricalTable.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
    query: PropTypes.object,
    hideTotalCol: PropTypes.bool,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(AlternateHistoricalTable);
