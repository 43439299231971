import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { ResponsivePie } from 'cccisd-nivo/pie';

import {
    convertAgeAtEnrollment,
    convertDemographicsPieChart,
    convertDemographicsBarChart,
    pieLegend,
    commonColumns,
} from './helpers.js';

export const sections = (groupLevel, groupLevelMap) => {
    return [
        {
            title: 'Age at Enrollment',
            name: 'ageAtEnrollment',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'meanAgeAtEnrollmentSD',
                    label: 'Mean Age at Enrollment (SD)',
                    sort: true,
                    tooltip: 'Age is calculated as of the End Date in the Date Range',
                    render(value, row, callbacks) {
                        return `${row.avgAge} (${row.sdAge})`;
                    },
                },
                {
                    name: 'range',
                    label: 'Range',
                    sort: true,
                    render(value, row, callbacks) {
                        return `${row.minAge} - ${row.maxAge}`;
                    },
                },
            ],
            convertData: convertAgeAtEnrollment,
            columnsCont: false,
            chart: data => (
                <div style={{ width: '770px', height: '300px' }}>
                    <ResponsiveBar
                        data={data}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        axisBottom={{
                            tickRotation: 45,
                        }}
                        labelFormat={d => <tspan y={-8}>{d}</tspan>}
                        labelSkipWidth={0}
                        labelSkipHeight={1}
                        margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
                    />
                </div>
            ),
            otherResponses: false,
        },
        {
            title: 'Gender Identity',
            name: 'genderIdentity',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'male',
                    label: 'Male',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'female',
                    label: 'Female',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'nonBinary',
                    label: 'Non-binary',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'other',
                    label: 'Other',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'preferNotToSay',
                    label: 'Prefer Not to Say',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsPieChart,
            columnsCont: false,
            chart: data => (
                <div style={{ width: '770px', height: '500px' }}>
                    <ResponsivePie
                        data={data}
                        enableArcLinkLabels={false}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={20}
                        legends={pieLegend}
                        margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                    />
                </div>
            ),
            otherResponses: true,
        },
        {
            title: 'Transgender Identity',
            name: 'transgenderIdentity',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'yes',
                    label: 'Yes',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'no',
                    label: 'No',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'questioning',
                    label: 'Questioning',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsPieChart,
            columnsCont: false,
            chart: data => (
                <div style={{ width: '770px', height: '500px' }}>
                    <ResponsivePie
                        data={data}
                        enableArcLinkLabels={false}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={20}
                        legends={pieLegend}
                        margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                    />
                </div>
            ),
            otherResponses: false,
        },
        {
            title: 'Sexual Orientation',
            name: 'sexualOrientation',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'asexual',
                    label: 'Asexual',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'bisexual',
                    label: 'Bisexual',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'gay',
                    label: 'Gay',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'straight',
                    label: 'Heterosexual/Straight',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'lesbian',
                    label: 'Lesbian',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsPieChart,
            columnsCont: [
                ...commonColumns(groupLevel),
                {
                    name: 'pansexual',
                    label: 'Pansexual',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'queer',
                    label: 'Queer',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'questioning',
                    label: 'Questioning',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'other',
                    label: 'Other',
                    tooltip: 'Sexual orientation not listed',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'preferNotToSay',
                    label: 'Prefer Not to Answer',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            chart: data => (
                <div style={{ width: '770px', height: '500px' }}>
                    <ResponsivePie
                        data={data}
                        enableArcLinkLabels={false}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={20}
                        legends={pieLegend}
                        margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                    />
                </div>
            ),
            otherResponses: true,
        },
        {
            title: 'Race',
            name: 'race',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'white',
                    label: 'White',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'blackAfricanAmerican',
                    label: 'Black/African American',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'nativeAmericanAlaskaNative',
                    label: 'Native American/Alaska Native',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'asian',
                    label: 'Asian',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'hawaiianPacificIslander',
                    label: 'Hawaiian/Pacific Islander',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsPieChart,
            columnsCont: [
                ...commonColumns(groupLevel),
                {
                    name: 'hispanicLatinoAsRaceOnly',
                    label: 'Hispanic/Latino as Race Only',
                    tooltip: 'Youth who did not select a race but marked Hispanic/Latio under ethnicity.',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'other',
                    label: 'Other',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'multiRacial',
                    label: 'Multi-Racial',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'preferNotToSay',
                    label: 'Prefer Not to Say',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            chart: data => (
                <div style={{ width: '770px', height: '500px' }}>
                    <ResponsivePie
                        data={data}
                        enableArcLinkLabels={false}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={20}
                        legends={pieLegend}
                        margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                    />
                </div>
            ),
            otherResponses: true,
        },
        {
            title: 'Ethnicity',
            name: 'ethnicity',
            desc: false,
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'hispanicLatino',
                    label: 'Hispanic/Latino',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'nonHispanicLatino',
                    label: 'Non-Hispanic/Latino',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsPieChart,
            columnsCont: false,
            chart: data => (
                <div style={{ width: '770px', height: '500px' }}>
                    <ResponsivePie
                        data={data}
                        enableArcLinkLabels={false}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={20}
                        legends={pieLegend}
                        margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                    />
                </div>
            ),
            otherResponses: false,
        },
        {
            title: 'Legal Custodian',
            name: 'legalCustodian',
            desc: 'This section displays the breakdown of legal custodians specified in the Youth Roster.',
            columns: [
                ...commonColumns(groupLevel),
                {
                    name: 'twoParents',
                    label: 'Two Parents',
                    tooltip: 'This includes two birth parents OR one birth parent & one step-parent.',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'birthMother',
                    label: 'Birth Mother Only',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'birthFather',
                    label: 'Birth Father Only',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'adoptiveParents',
                    label: 'Adoptive Parent(s)',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'fosterParents',
                    label: 'Foster Parent(s)',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'siblings',
                    label: 'Siblings',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            convertData: convertDemographicsBarChart,
            columnsCont: [
                ...commonColumns(groupLevel),
                {
                    name: 'auntUncle',
                    label: 'Aunt Uncle',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'grandparents',
                    label: 'Grandparent(s)',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'friends',
                    label: 'Friend(s)',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'stateWard',
                    label: 'Ward of the State',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'other',
                    label: 'Other',
                    sort: true,
                    render: value => value || 0,
                },
                {
                    name: 'missing',
                    label: 'Missing',
                    sort: true,
                    render: value => value || 0,
                },
            ],
            chart: data => (
                <div style={{ width: '770px', height: '300px' }}>
                    <ResponsiveBar
                        data={data}
                        theme={{ fontSize: 16 }}
                        keys={['count']}
                        axisBottom={{
                            tickRotation: 45,
                        }}
                        labelFormat={d => <tspan y={-8}>{d}</tspan>}
                        labelSkipWidth={0}
                        labelSkipHeight={1}
                        margin={{ top: 15, right: 150, bottom: 100, left: 50 }}
                    />
                </div>
            ),
            otherResponses: true,
        },
    ];
};

export default sections;
