import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { mean, round, sum } from 'lodash';

function KeyElementsOverallTable(props) {
    const { data, groupLevel, groupLevelMap } = props;

    const getData = () => {
        const rows = [];
        for (const group of Object.keys(groupLevelMap)) {
            const groupSiteItemScores = [];
            let groupItemNs = [];
            let sitesCountedAlready = [];
            for (const site of data.flows.KE.frequency) {
                if (groupLevelMap[group].includes(parseInt(site.value, 10))) {
                    if (site.count > 0) {
                        groupSiteItemScores.push(site.avg);
                        if (!sitesCountedAlready.includes(site.value)) {
                            groupItemNs.push(data.flows.KE.frequency.find(s => s.value === site.value).count);
                            sitesCountedAlready.push(site.value);
                        }
                    }
                }
            }

            if (groupSiteItemScores.length > 0) {
                rows.push({
                    groupLevel: group,
                    score: mean(groupSiteItemScores) || null,
                    groupItemN: sum(groupItemNs),
                });
            }
        }
        return rows;
    };

    const getTotal = td => {
        return {
            groupLevel: 'Total:',
            score: `${round(mean(td.map(row => row.score && row.score).filter(s => s !== null)), 2)}%`,
        };
    };

    const cols = [
        {
            name: 'groupLevel',
            filter: true,
            sort: true,
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        },
        {
            name: 'score',
            label: 'Key Elements Mean',
            render: (value, row) => (value !== null ? `${round(value, 2)}% N=${row.groupItemN}` : 'N/A*'),
        },
    ];

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            data={tableData}
            rowKey="groupLevel"
            orderBy="groupLevel"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="key-elements-overall"
        />
    );
}

KeyElementsOverallTable.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default KeyElementsOverallTable;
