import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import { subscales } from './subscales.js';
import query from './query.graphql';

// charts
import KeyElementsOverallChart from './charts/KeyElementsOverallChart';
import SubscaleItemChart from './charts/SubscaleItemChart';
import SubscalesOverallChart from './charts/SubscalesOverallChart';

// tables
// import ItemQuestionMeansTable from './tables/ItemQuestionMeansTable';
import ItemQuestionTable from './tables/ItemQuestionTable';
import KeyElementsOverallTable from './tables/KeyElementsOverallTable';
import SubscaleItemTable from './tables/SubscaleItemTable';
import SubscalesOverallTables from './tables/SubscalesOverallTables';

import {
    showTable,
    showChart,
    renderTitle,
    renderSubtitle,
    renderDescription,
    getGroupLevelMap,
} from 'js/vendor/reports/helpers.js';

function FidelitySection(props) {
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField));
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const response = await taskMasterClient.query({
            query,
            variables: {
                deploymentIds: props.filters.evalCycleSelectorField || [],
                pawnIds: props.youthPawnIds || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    return (
        <>
            {renderTitle('Key Elements Overall')}
            {renderDescription(
                'The Key Elements consist of 4 subscales: Driven by Strengths and Families, Natural and Community Supports, Needs-Based, and Outcomes-Based.'
            )}
            {showChart(<KeyElementsOverallChart data={data} groupLevelMap={groupLevelMap} />, loading)}
            {showTable(
                <KeyElementsOverallTable data={data} groupLevelMap={groupLevelMap} groupLevel={groupLevel} />,
                loading
            )}
            {renderTitle('Subscale and Item Responses')}
            {renderSubtitle('Overview of Subscale Scores')}
            {showChart(
                <SubscalesOverallChart data={data} subscales={subscales} groupLevelMap={groupLevelMap} />,
                loading
            )}
            {showTable(
                <SubscalesOverallTables
                    data={data}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                    subscales={subscales}
                />,
                loading
            )}
            {subscales
                .filter(s => s.hasOwnSection)
                .map(subscale => (
                    <div key={subscale.name}>
                        {renderTitle(subscale.label)}
                        {renderDescription(subscale.desc)}
                        {showChart(
                            <SubscaleItemChart data={data} subscale={subscale} groupLevelMap={groupLevelMap} />,
                            loading
                        )}
                        {showTable(
                            <SubscaleItemTable
                                data={data}
                                subscale={subscale}
                                groupLevelMap={groupLevelMap}
                                groupLevel={groupLevel}
                            />,
                            loading
                        )}
                        {/* {subscale.hasQuestionTable &&
                            showTable(<ItemQuestionMeansTable items={subscale.items} />, loading)} */}
                        {subscale.hasQuestionTable && showTable(<ItemQuestionTable items={subscale.items} />, loading)}
                    </div>
                ))}
        </>
    );
}

FidelitySection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(FidelitySection);
