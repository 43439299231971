import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import ResponseRatesTable from './tables/ResponseRatesTable';
import DataCollectionInfoTable from './tables/DataCollectionInfoTable';
import OptOutReasonTable from './tables/OptOutReasonTable';
import ExclusionInfoTable from './tables/ExclusionInfoTable';
import DuplicateInfoTable from './tables/DuplicateInfoTable';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';

import cycleInfoQuery from './graphql/cycleInfo.graphql';
import { showTable, renderSubtitle, renderDescription } from 'js/vendor/reports/helpers.js';
import OtherOptOutReasonTable from './tables/OtherOptOutReasonTable';

function SampleResponseSummary(props) {
    const [cycleInfo, setCycleInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);

            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const cycleInfoResponse = await taskMasterClient.query({
            query: cycleInfoQuery,
            variables: {
                deploymentIds: props.filters.evalCycleSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        setCycleInfo(cycleInfoResponse.data);
        setLoading(false);

        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            {renderSubtitle('Evaluation Cycles and Completion Rates')}
            {showTable(<ResponseRatesTable data={cycleInfo} groups={props.groups} />, loading)}
            {renderSubtitle('Data Collection Information for DART')}
            {showTable(<DataCollectionInfoTable data={cycleInfo} groups={props.groups} />, loading)}
            {renderSubtitle('Opt-Out Reason for DART')}
            {showTable(<OptOutReasonTable data={cycleInfo} groups={props.groups} />, loading)}
            {showTable(
                <Collapsable
                    title="Other Responses (Click to Show)"
                    collapsed
                    content={<OtherOptOutReasonTable data={cycleInfo} groups={props.groups} />}
                />,
                loading
            )}
            {renderSubtitle('Exclusion Information')}
            {showTable(<ExclusionInfoTable data={cycleInfo} groups={props.groups} />, loading)}
            {renderSubtitle('Duplicate Information')}
            {renderDescription(
                'This section gives you information about youth and associated respondents whose data is present in two or more Evaluation Cycles in this report.'
            )}
            {showTable(<DuplicateInfoTable data={cycleInfo} groups={props.groups} />, loading)}
        </>
    );
}

SampleResponseSummary.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(SampleResponseSummary);
