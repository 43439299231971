import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Component from './component.js';
import { loadGroups } from 'js/reducers/sites.js';
import hash from 'object-hash';
import moment from 'moment';

const previousSitesKey = `${window.cccisd.fortress.user.acting.respondent_hash}-sites`;

const Player = props => {
    const groups = useSelector(state => state.app.sites.groups);
    const [sites, setSites] = useState([]);
    const dispatch = useDispatch();

    const prevSites = localStorage.getItem(previousSitesKey)
        ? localStorage
              .getItem(previousSitesKey)
              .split(',')
              .map(s => parseInt(s, 10))
        : false;

    useEffect(() => {
        if (groups.length === 0) {
            (async () => {
                await dispatch(loadGroups());
                getInitialSites(groups);
            })();
        }
    }, []);

    useEffect(() => {
        getInitialSites(groups);
    }, [hash(prevSites)]);

    const getInitialSites = g => {
        var siteIds = [];
        const isUber = window.cccisd.fortress.user.acting.data_type === 'uberadmin';
        for (var group of g) {
            if (group.group.groupType === 'site') {
                siteIds.push(group.group.groupId);
            }
        }
        if (!prevSites) {
            localStorage.setItem(previousSitesKey, siteIds);
        }
        if (isUber) {
            setSites(prevSites || siteIds);
        } else {
            setSites(siteIds);
        }
    };

    if (groups.length === 0 || sites.length === 0) {
        return null;
    }

    const getDate15th = now => {
        if (now.getDate() > 15) {
            now.setDate(15);
            return now;
        }
        now.setDate(15);
        return moment(now)
            .subtract(1, 'months')
            .toDate();
    };
    const now = new Date();
    const toDate = getDate15th(now);

    const initialFilterValues = {
        monthSelectorFrom: moment(toDate)
            .subtract(12, 'months')
            .toDate(),
        monthSelectorTo: toDate,
        siteSelectorField: sites,
        filterHack: true,
    };

    return (
        <Component
            initialFilterValues={initialFilterValues}
            defaultSites={sites}
            groups={groups}
            {...props}
        />
    );
};

export default Player;
