import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { taskMasterClient } from 'cccisd-apollo';
import Table from 'cccisd-table';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import { sections } from './sections.js';
import query from './query.graphql';
import { std } from './helpers.js';
import { round, max, min, mean } from 'lodash';

import { showChart, showTable, renderSubtitle, getGroupLevelMap } from 'js/vendor/reports/helpers.js';

function ReportOverviewSection(props) {
    const [sectionData, setSectionData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';

    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            const levelMap = getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField);
            setGroupLevelMap(levelMap);
            getSectionData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getSectionData = async () => {
        const variables = {
            pawnIds: props.youthPawnIds || [],
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setSectionData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    const otherResponseTableData = (data, section) => {
        const rows = [];
        const key = `${section.name}_otherResponses`;
        if (data.roles[key]) {
            if (data.roles[key].frequency) {
                for (const cc of data.roles[key].frequency) {
                    const gl = Object.keys(groupLevelMap).find(g => groupLevelMap[g].includes(parseInt(cc.value, 10)));
                    for (const res of cc.frequency) {
                        if (res.value) {
                            rows.push({
                                groupLevelKey: `${gl}-${res.value}-${100 * Math.random()}`,
                                groupLevel: gl,
                                response: res.value,
                            });
                        }
                    }
                }
            }
        }
        return rows;
    };

    const getTableData = (data, section, cols) => {
        const rows = [];
        const groupMap = {};
        for (const col of cols) {
            if (!['groupLevel', 'numberOfYouth'].includes(col.name)) {
                const key = `${section.name}_${col.name}`;
                if (data.roles[key]) {
                    if (data.roles[key].frequency) {
                        for (const gl of Object.keys(groupLevelMap)) {
                            const sites = groupLevelMap[gl];
                            for (const siteFreq of data.roles[key].frequency) {
                                if (sites.includes(parseInt(siteFreq.value, 10))) {
                                    for (const valFreq of siteFreq.frequency) {
                                        if (valFreq.value || col.name === 'missing') {
                                            if (groupMap[gl]) {
                                                if (groupMap[gl][col.name]) {
                                                    groupMap[gl][col.name] += valFreq.valueCount;
                                                } else {
                                                    groupMap[gl][col.name] = valFreq.valueCount;
                                                }
                                            } else {
                                                groupMap[gl] = {
                                                    [col.name]: valFreq.valueCount,
                                                };
                                            }
                                        }
                                    }
                                }
                            }
                            if (groupMap[gl]) {
                                let numYouth = 0;

                                for (let k of Object.keys(groupMap[gl])) {
                                    if (k !== 'groupLevel' && k !== 'numberOfYouth' && groupMap[gl][k]) {
                                        numYouth += groupMap[gl][k];
                                    }
                                }
                                groupMap[gl].numberOfYouth = numYouth;
                            }
                        }
                    }
                }
            }
        }
        for (const g of Object.keys(groupMap)) {
            rows.push({
                groupLevel: g,
                ...groupMap[g],
            });
        }
        return rows;
    };

    const ageAtEnrollTableData = data => {
        const rows = [];
        for (const gl of Object.keys(groupLevelMap)) {
            const groupAgeList = [];
            const sites = groupLevelMap[gl];
            for (const siteWithEnrolledYouth of data.roles.ageAtEnrollment.frequency) {
                if (sites.includes(parseInt(siteWithEnrolledYouth.value, 10))) {
                    for (const age of siteWithEnrolledYouth.frequency) {
                        for (let i = 0; i < age.valueCount; i++) {
                            groupAgeList.push(parseInt(age.value, 10));
                        }
                    }
                }
            }
            if (groupAgeList.length > 0) {
                rows.push({
                    groupLevel: gl,
                    numberOfYouth: groupAgeList.length,
                    minAge: min(groupAgeList) || 0,
                    maxAge: max(groupAgeList) || 0,
                    avgAge: round(mean(groupAgeList), 2) || 0,
                    sdAge: round(std(groupAgeList), 2) || 0,
                });
            }
        }

        return rows;
    };

    const summarize = (data, cols) => {
        const summary = {};
        if (!data) return summary;
        data.forEach(row => {
            for (const column of Object.keys(row)) {
                if (row[column]) {
                    if (column in summary) {
                        summary[column] += row[column];
                    } else {
                        summary[column] = row[column];
                    }
                }
            }
        });
        if (cols) {
            for (let missingCol of cols.filter(c => !Object.keys(summary).includes(c))) {
                summary[missingCol] = 0;
            }
        }
        summary.groupLevel = 'Total:';
        return summary;
    };

    const getAgeAtEnrollTotal = data => {
        const allAgeList = [];
        // push all ages
        for (const siteWithEnrolledYouth of data.roles.ageAtEnrollment.frequency) {
            for (const age of siteWithEnrolledYouth.frequency) {
                for (let i = 0; i < age.valueCount; i++) {
                    allAgeList.push(parseInt(age.value, 10));
                }
            }
        }
        const ageMean = round(mean(allAgeList), 2) || 0;
        const ageSD = round(std(allAgeList), 2) || 0;
        const ageMin = min(allAgeList) || 0;
        const ageMax = max(allAgeList) || 0;
        const d = {
            groupLevel: 'Total:',
            numberOfYouth: allAgeList.length,
            meanAgeAtEnrollmentSD: `${ageMean} (${ageSD})`,
            range: `${ageMin} - ${ageMax}`,
        };
        return d;
    };

    if (loading) {
        return <Skeleton rows={15} />;
    }

    return (
        <div>
            {sections(groupLevel, groupLevelMap).map(section => {
                const tableData =
                    !loading && section.name === 'ageAtEnrollment'
                        ? ageAtEnrollTableData(sectionData)
                        : getTableData(sectionData, section, section.columns);
                const tableContData =
                    !loading && section.columnsCont && getTableData(sectionData, section, section.columnsCont);
                const tableOtherData =
                    !loading && section.otherResponses ? otherResponseTableData(sectionData, section) : [];
                const tableSummary =
                    !loading && section.name === 'ageAtEnrollment'
                        ? getAgeAtEnrollTotal(sectionData)
                        : summarize(
                              tableData,
                              section.columns.map(c => c.name)
                          );
                const tableContSummary =
                    !loading &&
                    section.columnsCont &&
                    summarize(
                        tableContData,
                        section.columnsCont.map(c => c.name)
                    );

                return (
                    <div key={section.title}>
                        {renderSubtitle(section.title)}
                        {showChart(section.chart(section.convertData(sectionData, section, groupLevelMap)), loading)}
                        {showTable(
                            <Table
                                columns={section.columns}
                                data={tableData}
                                rowKey="groupLevel"
                                orderBy="groupLevel"
                                perPage={5000}
                                perPageOptions={[25, 50, 100, 500]}
                                isCsvDownload
                                csvFilename={section.title.replaceAll(' ', '-').toLowerCase()}
                                footer={tableSummary}
                            />,
                            loading
                        )}
                        {section.columnsCont &&
                            tableContData &&
                            showTable(
                                <Table
                                    columns={section.columnsCont}
                                    data={tableContData}
                                    rowKey="groupLevel"
                                    orderBy="groupLevel"
                                    perPage={5000}
                                    perPageOptions={[25, 50, 100, 500]}
                                    isCsvDownload
                                    csvFilename={section.title.replaceAll(' ', '-').toLowerCase()}
                                    footer={tableContSummary}
                                />,
                                loading
                            )}
                        {section.otherResponses &&
                            showTable(
                                <Collapsable
                                    title={`${section.title} "Other" Responses`}
                                    collapsed
                                    content={
                                        <Table
                                            data={tableOtherData}
                                            rowKey="groupLevelKey"
                                            orderBy="groupLevelKey"
                                            columns={[
                                                {
                                                    name: 'groupLevel',
                                                    label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
                                                    filter: true,
                                                    sort: true,
                                                },
                                                {
                                                    name: 'response',
                                                    label: 'Response',
                                                    sort: true,
                                                    filter: true,
                                                },
                                            ]}
                                            perPage={5000}
                                            perPageOptions={[25, 50, 100, 500]}
                                            isCsvDownload
                                            csvFilename={section.title.replaceAll(' ', '-').toLowerCase()}
                                        />
                                    }
                                />,
                                loading
                            )}
                    </div>
                );
            })}
        </div>
    );
}

ReportOverviewSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(ReportOverviewSection);
