export const subscales = [
    {
        name: 'attendance',
        desc: 'The Meeting Attendance subscale comprises 6 items: E1, E2, E3, E4, E5, and E6.',
        label: 'Meeting Attendance',
        key: 'MA',
        items: ['E1', 'E2', 'E3', 'E4', 'E5', 'E6'],
        hasOwnSection: true,
        hasQuestionTable: true,
    },
    {
        name: 'family',
        desc: 'The Driven by Strengths and Families subscale consists of items E1, E2, E7, E8, E9, E10, E11, and E13. Items have three response options, Fully Met/Yes (2), Partially Met (1), and Not Met/No (0). Some items have additional options, such as N/A or Miss, which renders the item invalid.',
        label: 'Driven by Strengths and Families',
        key: 'DSF',
        items: ['E1', 'E2', 'E7', 'E8', 'E9', 'E10', 'E11', 'E13'],
        keyElement: true,
        hasOwnSection: true,
        hasQuestionTable: true,
    },
    {
        name: 'support',
        desc: 'The Natural and Community Supports subscale consists of items E6, E12, E14, E19, E20, and E21. Items have three response options, Fully Met/Yes (2), Partially Met (1), and Not Met/No (0). Some items have additional options, such as N/A or Miss, which renders the item invalid.',
        label: 'Natural and Community Supports',
        key: 'NCS',
        items: ['E6', 'E12', 'E14', 'E19', 'E20', 'E21'],
        keyElement: true,
        hasOwnSection: true,
        hasQuestionTable: true,
    },
    {
        name: 'needs',
        desc: 'The Needs-Based subscale consists of items E15, E16, E17, and E18. Items have three response options, Fully Met/Yes (2), Partially Met (1), and Not Met/No (0). Some items have additional options, such as N/A or Miss, which renders the item invalid.',
        label: 'Needs-Based',
        key: 'NB',
        items: ['E15', 'E16', 'E17', 'E18'],
        keyElement: true,
        hasOwnSection: true,
        hasQuestionTable: true,
    },
    {
        name: 'outcomes',
        desc: 'The Outcomes-Based subscale consists of items E22, E23, E24, and E25. Items have three response options, Fully Met/Yes (2), Partially Met (1), and Not Met/No (0). Some items have additional options, such as N/A or Miss, which renders the item invalid. ',
        label: 'Outcomes-Based',
        key: 'OB',
        items: ['E22', 'E23', 'E24', 'E25'],
        keyElement: true,
        hasOwnSection: true,
        hasQuestionTable: true,
    },
    {
        name: 'time',
        key: 'TE',
        label: 'Timely Engagement',
        items: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7'],
    },
    {
        name: 'safety',
        key: 'SP',
        label: 'Safety Planning',
        items: ['F1', 'F2', 'F3'],
    },
    {
        name: 'crisis',
        key: 'CR',
        label: 'Crisis Response',
        items: ['G1', 'G2', 'G3'],
    },
    {
        name: 'transition',
        key: 'TP',
        label: 'Transition Planning',
        items: ['H1', 'H2', 'H3', 'H4'],
    },
    {
        name: 'outcomes2',
        key: 'OC',
        label: 'Outcomes',
        items: ['I1', 'I2', 'I3', 'I4', 'I5', 'I6', 'I7'],
    },
];
