import Builder, { initialValues as builderInitialValues } from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';
import FidelitySection from './custom/sections/FidelitySection';
import SampleResponseSummarySection from './custom/sections/SampleResponseSummary';
import ReportOverviewSection from './custom/sections/ReportOverview';

import { siteTableFilter } from './queryFilters.js';

const respondentSummarySections = [
    {
        name: 'respondentSummary',
        label: 'Respondent Summary',
        tables: [
            {
                name: 'respondentSummary',
                label: 'Respondent Summary',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [],
    },
];

const reportOverviewSections = [
    {
        name: 'reportOverview',
        label: 'Report Overview',
        component: ReportOverviewSection,
    },
];

const fidelitySections = [
    {
        name: 'fidelity',
        label: 'DART Scores', // a.k.a. fidelity
        component: FidelitySection,
    },
];

const responseSummarySections = [
    {
        name: 'responseSummary',
        label: 'Sample and Cycle Completion Summary',
        component: SampleResponseSummarySection,
    },
];

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: respondentSummarySections,
    },
    {
        handle: 'responseSummary',
        label: 'Sample and Cycle Completion Summary',
        sections: responseSummarySections,
    },
    {
        handle: 'reportOverview',
        label: 'Report Overview',
        sections: reportOverviewSections,
    },
    {
        handle: 'fidelity',
        label: 'DART Scores', // a.k.a. fidelity
        sections: fidelitySections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
