import { startCase } from 'lodash';

export const convertDemographicsChart = (data, filters) => {
    let counts = {};
    let totalCount = 0;
    for (var o = 0; o < Object.keys(data.roles).length - 1; o++) {
        const key = Object.keys(data.roles)[o];
        if (key !== 'youthList') {
            const val = data.roles[key];
            totalCount += val;
            counts[key] = val || counts[key] + val;
        }
    }
    return Object.keys(counts).map(a => ({
        id: startCase(a),
        label: `${startCase(a)} (${counts[a] > 0 ? ((counts[a] / totalCount) * 100).toFixed(2) : 0}%)`,
        value: `${counts[a] || 0}`,
    }));
};

export const convertDemographicsBarChart = (data, filters) => {
    let counts = {};
    for (var o = 0; o < Object.keys(data.roles).length - 1; o++) {
        const key = Object.keys(data.roles)[o];
        if (key !== 'youthList') {
            const val = data.roles[key];
            counts[key] = val || counts[key] + val;
        }
    }
    return Object.keys(counts).map(a => ({
        id: startCase(a),
        count: counts[a] || 0,
    }));
};

export const convertExitReasonBarChart = (data, filters) => {
    const d = data.roles.youthSummary.frequency
        ? data.roles.youthSummary.frequency
              .map(a => {
                  return (
                      a.value !== null &&
                      a.value !== '' && {
                          id: a.value,
                          count: a.valueCount,
                      }
                  );
              })
              .filter(item => item !== false)
        : [];

    const orderLabelMap = {
        completedMetGoalsPOCSteppedDown: 'Compl. Step-down',
        completedMetGoalsPOCDischarged: 'Compl. Met Goals on POC',
        incompleteNoEngagement: 'Inc. No Engagement',
        incompleteFamilyChoice: 'Inc. Family Choice',
        incompleteHigherLevelOfCare: 'Inc. Higher Level of Care',
        incompleteExcludedSetting: 'Inc. Excluded Setting',
        incompleteDetention: 'Inc. Detention',
        ineligibleAgedOut: 'Inel. Aged Out',
        ineligibleLossOfInsurance: 'Inel. Loss of Insurance',
        ineligibleChangeInIncome: 'Inel. Change in Income',
        relocated: 'Relocated',
        other: 'Other',
    };
    var chartData = [];
    for (var col of Object.keys(orderLabelMap)) {
        const item = d.find(o => o.id === col);
        if (item) {
            chartData.push({ id: orderLabelMap[col], count: item.count });
        }
    }
    return chartData;
};

export const ageAtEnrollmentConvertData = data => {
    const underflow = '0+';
    const overflow = '19+';
    const sortedKeys = [
        '0+',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19+',
    ];
    const freq = {
        [underflow]: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        [overflow]: 0,
    };
    var siteFreq = data.roles.youthSummary.frequency;
    for (var o = 0; o < siteFreq.length; o++) {
        var count = siteFreq[o];
        var age = parseInt(count.value, 10);
        if (age > 18) {
            freq[overflow] += count.valueCount;
        }
        if (age < 1) {
            freq[underflow] += count.valueCount;
        } else if (Object.keys(freq).includes(count.value)) {
            freq[count.value] += count.valueCount;
        }
    }
    return sortedKeys.map(a => ({ id: a, count: freq[a] }));
};
