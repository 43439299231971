import React from 'react';
import PropTypes from 'prop-types';
import widgets, { templateViews } from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
// import { client, taskMasterClient } from 'cccisd-apollo';
import filterFields from '../filterFields.js';
import { connect } from 'react-redux';
import { resetWidgetsLoaded, setYouthPawnIds } from 'js/reducers/report.js';
import TemplateWrapper from 'js/vendor/reports/components/TemplateWrapper';
import { getGroupCount, groupChildren } from 'js/vendor/reports/helpers.js';

const Component = props => {
    const { siteSelectorField } = props.filters;

    const { settings, groups } = props;

    // useEffect(() => {
    //     if (siteSelectorField) {
    //         client.cancelQueries('report');
    //         taskMasterClient.cancelQueries('report');
    //         props.resetWidgetsLoaded();
    //     }
    // }, [siteSelectorField]);

    const headerGroups = settings.groupLevel
        ? groupChildren[settings.groupLevel].map(g => ({
              title: `Number of ${g.label}`,
              value: getGroupCount(g.name, siteSelectorField, groups),
              showIf: true,
          }))
        : [{ title: 'Groups', value: 'Select Group Level from Report Settings', showIf: true }];

    return (
        <TemplateWrapper
            settings={settings}
            summary={headerGroups}
            initialFilterValues={props.initialFilterValues}
            filters={props.filters}
            widgetsConfig={widgets}
            widgetComponents={props.widgets}
            templateViews={templateViews}
            alwaysShownWidgets={[]}
            shouldShowWidget={w => settings[w.handle]}
            showWhen
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    groups: PropTypes.array,
    initialFilterValues: PropTypes.object,
    // redux
    resetWidgetsLoaded: PropTypes.func,
    setYouthPawnIds: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    youthPawnIds: state.app.report.youthPawnIds,
});
const Connected = connect(mapStateToProps, { resetWidgetsLoaded, setYouthPawnIds })(Component);

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        const defaults = filterFields.map(f => ({
            ...f,
            initialValue: props.initialFilterValues[f.name],
        }));
        return defaults;
    },
})(Connected);

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
